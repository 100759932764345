* {
    margin: 0;
    padding: 0;
}

.body-login {
    width: 100%;
    height: 100vh;
    background: #456;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login {
    width: 400px;
    margin: 16px auto;
    font-size: 16px;
}

/* Reset top and bottom margins from certain elements */
.login-header,
.login p {
    margin-top: 0;
    margin-bottom: 0;
}

/* The triangle form is achieved by a CSS hack */
.login-triangle {
    width: 0;
    margin-right: auto;
    margin-left: auto;
    border: 12px solid transparent;
    border-bottom-color: #28d;
}

.login-header {
    background: #28d;
    padding: 20px;
    font-size: 1.4em;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

.login-container {
    background: #ebebeb;
    padding: 12px;
}

/* Every row inside .login-container is defined with p tags */
.login p {
    padding: 12px;
}

.login input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    padding: 16px;
    outline: 0;
    font-family: inherit;
    font-size: 0.95em;
}

.login input[type="email"],
.login input[type="password"] {
    background: #fff;
    border-color: #bbb;
    color: #555;
}

/* Text fields' focus effect */
.login input[type="email"]:focus,
.login input[type="password"]:focus {
    border-color: #888;
}

.login button {
    width: 95%;
    height: 50px;
    background: #28d;
    border-color: transparent;
    color: #fff;
    cursor: pointer;
}

.login button:hover {
    background: #17c;
}

/* Buttons' focus effect */
.login button:focus {
    border-color: #05a;
}