@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Nunito Sans', sans-serif;
}

.body {
        background-color: #1D2049;
}

@keyframes appear {
        from {
                opacity: 0;
                scale: .5;
        }

        to {
                opacity: 1;
                scale: 1;
        }
}

#scale-animation-id {
        animation: appear linear;
        animation-timeline: view();
        animation-range: entry 0;
}




.greenTextMe {
        color: #41C981;
        font-weight: 800;
        font-size: 19px;
        font-family: 'Nunito Sans', sans-serif;

}

.borderWi {
        border-top: 1px solid #858282;
}

.card-border-radius {
        border-radius: 18px 18px 0 0;
}

.card-border-radius-2 {
        border-radius: 12px;
        box-shadow: 0px 8px 15px 4px #0000000D;
        margin: 45px 0;
}

.card-border-radius-3 {
        border-radius: 12px;
        box-shadow: 0px 8px 15px 4px #292c4f;
        margin: 45px 0;
}


.btn-hover {
        background-color: #41C981;
        color: #fff;
        transition-duration: .8s;

}

.btn-hover:hover {
        border: 1px #41C981;
        background-color: #fff;
        color: #41C981;
        box-shadow: 0px 8px 15px 4px #0000000D;
}

.borderGreen {
        border: .6px solid #41C981;
}

.durationText {
        font-weight: 400;
        transition-duration: .8s;
}

.durationText:hover {
        font-weight: 800;
}

.scaleCard {
        transform: scale(100%);
        transition-duration: .8s;
}

.scaleCard:hover {
        transform: scale(108%);
}


.color-border {
        border: 1px solid #41C981;
}

.color-border-1:hover {
        border: 1px solid #41C981;
}

*::selection {
        background-color: darkgoldenrod;
        color: white;
}

*::-webkit-scrollbar {
        display: block;
        overflow-x: hidden;
}

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
*::-webkit-scrollbar {
        width: 7px;
        height: 8px;
        background-color: #fff;
        /* or add it to the track */
}

/* Add a thumb */
*::-webkit-scrollbar-thumb {
        background: #41C981;
        border-radius: 10px;
}


.homeFace {
        background: url("./img/home.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 1440px;
        min-height: 600px;
}

/* ****************************************** corusel ******************************************************** */

.slick-slide>div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
}

/* ****************************************** corusel ******************************************************** */


.homeFace>div {
        width: 100%;
        min-height: 600px;
        background: rgba(0, 0, 0, .4);
        text-emphasis: none;
}


.shadowing>.overflow-hidden>img:hover {
        transition: all .9s;
        overflow: hidden;
        transform: scale(1.1);
}

.cardtwoGalety {
        border-radius: 50%;
}

.CardGalery {

        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}



/* ****************************************************************** */



/* *************************************** */
.scale-up-hor-right {
        -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/**
 * ----------------------------------------
 * animation scale-up-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-hor-right {
        0% {
                -webkit-transform: scaleX(0.4);
                transform: scaleX(0.4);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
        }

        100% {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
        }
}

@keyframes scale-up-hor-right {
        0% {
                -webkit-transform: scaleX(0.4);
                transform: scaleX(0.4);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
        }

        100% {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
        }
}




/* ******************************************************************************* */


.scale-down-hor-right {
        -webkit-animation: scale-down-hor-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-down-hor-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}



/**
 * ----------------------------------------
 * animation scale-down-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-hor-right {
        0% {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
        }

        100% {
                -webkit-transform: scaleX(0.3);
                transform: scaleX(0.3);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
        }
}

@keyframes scale-down-hor-right {
        0% {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
        }

        100% {
                -webkit-transform: scaleX(0.3);
                transform: scaleX(0.3);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
        }
}




/* ************************************************** */

.btn-contact {
        padding: 8px 22px;
        font-size: 24px;
        font-weight: 600;
        background-color: white;
        border: 1px solid #41C981;
        color: #41C981;
        border-radius: 16px;
}

.btn-contact:hover {
        padding: 8px 22px;
        font-size: 24px;
        font-weight: 600;
        background-color: #41C981;
        border: 1px solid #fff;
        color: white;
        border-radius: 16px;
        transition-duration: .8s;
}




/* *****************************************  rotate answer to question ********************************** */



.rotate-center {
        -webkit-animation: rotate-center 0.6s ease-in-out both;
        animation: rotate-center 0.6s ease-in-out both;
}


/* ----------------------------------------------
 * Generated by Animista on 2024-5-15 13:52:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
        0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
        }

        100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

@keyframes rotate-center {
        0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
        }

        100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

/* ////////////////////////////////////////////////////////////////////////////// */

.rotate-center-360 {
        -webkit-animation: rotate-center-360 0.6s ease-in-out both;
        animation: rotate-center-360 0.6s ease-in-out both;
}


/* ----------------------------------------------
 * Generated by Animista on 2024-5-15 13:52:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center-360 {
        0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
        }

        100% {
                -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
        }
}

@keyframes rotate-center-360 {
        0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
        }

        100% {
                -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
        }
}





/* *****************/
/*/*/
/*/**/
/*/***/
/* */



.slide-top {
        -webkit-animation: slide-top 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-top {
        0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
        }

        100% {
                -webkit-transform: translateY(0px);
                transform: translateY(0px);
        }
}

@keyframes slide-top {
        0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
        }

        100% {
                -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
        }
}




/* *****************************************************  loader css ********************************************************* */
.loader {
        width: 200px;
        height: 140px;
        background: #979794;
        box-sizing: border-box;
        position: relative;
        border-radius: 8px;
        perspective: 1000px;
}

.loader:before {
        content: '';
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        border-radius: 8px;
        background: #f5f5f5 no-repeat;
        background-size: 60px 10px;
        background-image: linear-gradient(#ddd 100px, transparent 0),
                linear-gradient(#ddd 100px, transparent 0),
                linear-gradient(#ddd 100px, transparent 0),
                linear-gradient(#ddd 100px, transparent 0),
                linear-gradient(#ddd 100px, transparent 0),
                linear-gradient(#ddd 100px, transparent 0);

        background-position: 15px 30px, 15px 60px, 15px 90px,
                105px 30px, 105px 60px, 105px 90px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.loader:after {
        content: '';
        position: absolute;
        width: calc(50% - 10px);
        right: 10px;
        top: 10px;
        bottom: 10px;
        border-radius: 8px;
        background: #fff no-repeat;
        background-size: 60px 10px;
        background-image: linear-gradient(#ddd 100px, transparent 0),
                linear-gradient(#ddd 100px, transparent 0),
                linear-gradient(#ddd 100px, transparent 0);
        background-position: 50% 30px, 50% 60px, 50% 90px;
        transform: rotateY(0deg);
        transform-origin: left center;
        animation: paging 1s linear infinite;
}


@keyframes paging {
        to {
                transform: rotateY(-180deg);
        }
}





@-webkit-keyframes rotating {
        from {
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
        }

        to {
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

@keyframes rotating {
        from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
        }

        to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

.rotating {
        -webkit-animation: rotating 2s linear infinite;
        -moz-animation: rotating 2s linear infinite;
        -ms-animation: rotating 2s linear infinite;
        -o-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;
}


.scale-up-ver-top {
        -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-24 13:34:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-top {
        0% {
                -webkit-transform: scaleY(0.4);
                transform: scaleY(0.4);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        }

        100% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        }
}

@keyframes scale-up-ver-top {
        0% {
                -webkit-transform: scaleY(0.4);
                transform: scaleY(0.4);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        }

        100% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        }
}